footer
  border-top 2px solid gc(black1)
  border-bottom 2px solid gc(black1)
  padding 6px 0
  margin 50px 0 
  > .inner
    border-top 1px solid gc(black1)
    border-bottom 1px solid gc(black1)
    text-align center
    padding 10px 0 15px 0
