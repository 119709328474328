.burger
  position fixed
  top 20px
  left 20px
  clear both
  width 40px
  height 40px
  z-index 150
  &.off > .lines > span
    background-color gc(brown1)
  &.off > .lines > span:nth-child(1)
    top 18px
    width 0%
    left 50%
  &.off > .lines > span:nth-child(2)
    transform rotate(45deg)
  &.off > .lines > span:nth-child(3)
    transform rotate(-45deg)
  &.off > .lines > span:nth-child(4)
    top 18px
    width 0%
    left 50%

  > .lines
    position relative
    width 100%
    height 100%
    > span
      display block
      position absolute
      height 3px
      border-radius 9px
      width 100%
      background-color gc(brown1)
      opacity 1
      left 0
      transition 0.25s ease-in-out
      &:nth-child(1)
        top 0px
      &:nth-child(2),
      &:nth-child(3)
        top 14px
      &:nth-child(4)
        top 28px

