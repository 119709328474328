@keyframes fadeIn
  from
    opacity 0
  to
    opacity 1

@keyframes scaleIn
  from
    opacity 0
    transform scale(0.5)
  to
    opacity 1
    transform scale(1)

@keyframes scaleInB
  from
    opacity 0
    transform scale(1.2)
  to
    opacity 1
    transform scale(1)


@keyframes inFromTop
  from
    transform translate(0, -10px)
    opacity 0
  to
    transform translate(0, 0)
    opacity 1

@keyframes inFromBottom
  from
    transform translate(0, 10px)
    opacity 0
  to
    transform translate(0, 0)
    opacity 1



@keyframes inFromRight
  from
    transform translate(10px, 0)
    opacity 0
  to
    transform translate(0, 0)
    opacity 1


@keyframes inFromLeft
  from
    transform translate(-10px, 0)
    opacity 0
  to
    transform translate(0, 0)
    opacity 1


