
vendor-prefixes ?= webkit moz o ms official

@import '/includes/mixins'
@import '/includes/*'
@import '/partial/*'
@import guide

.clear
  clearfix()

body
  padding 0
  margin 0
  background-color gc(beige2)
  background-image url(/images/pattern/paper.png)
  gf(copy1)
  text-align justify

a
  color gc(brown3)

@import 'index.styl'
