.container
  width 980px
  margin auto

.title
  gf(h1)
  text-align center
  text-transform uppercase
  padding 0 0 30px 0

.fakemenu
  height 68px

.section
  padding 30px 0
  &.section_authors
    padding  0
  > .copy

    &.left
      float left
    &.right
      float right
    &.full
      padding 30px 0 0 0

    > .first
      gf(copy2)
      float left
      line-height 60px
      margin 20px 10px 10px 0
    > .subheader
      > .copy
        gf(h2)
        text-align center
        line-height 20px
        letter-spacing 5px
        padding 25px 0 0 0

    &.scope1
      width 570px
      padding 20px 0 0 0

    &.history1
      width 560px
    &.collection
      width 520px
    &.howto
      width 650px
      > ul
        list-style-type decimal
        margin 0
        padding 0 0 20px 25px
    &.credits
      width 800px
      margin auto

  > .authors
    margin auto
    width 860px
    > .title
      gf(copy1)
      text-transform none
      padding 0 0 20px 0

    > .authorlist
      > .author
        float left
        width 206px
        > .asset
          background-size contain
          background-repeat no-repeat
          background-position 50% 50%
          height 220px
          width 220px
          margin auto
        &.author_joeb > .asset
          background-image url(/images/joeb.png)
        &.author_lucam > .asset
          background-image url(/images/lucam.png)
        &.author_kevinc > .asset
          background-image url(/images/kevinc.png)
        &.author_kevino > .asset
          background-image url(/images/kevino.png)
        > .name
          width 220px
          margin auto
          text-align center
          padding 10px 0 0 0

    
  > .asset
    background-size cover
    &.right
      float right
    &.left
      float left
    &.scope1
      width 330px
      height 245px
      background-image url(/images/stereoscope1.png)
    &.brewster
      width 330px
      height 245px
      background-image url(/images/brewster.png)
    &.globe
      width 360px
      height 468px
      background-image url(/images/globe.png)
    &.howto
      width 300px
      height 190px
      background-image url(/images/howto.png)
      margin 70px 0 0 0

@media all and (min-width: 1px) and (max-width: 1000px)
  header
    > .inner
      padding 10px
  .container
    width auto
    padding 0 10px


    > .inner
      > .title
        font-size 20px

  .section
    padding 10px
    > .copy
      width auto !important
      &.left
        float none
      &.right
        float none
      > .subheader
        > .copy
          line-height 50px
          font-size 40px

    > .asset
      margin 20px auto
      &.left
        float none
      &.right
        float none

      &.globe
        width 320px
        height 417px

      &.howto
        margin auto
    > .authors
      float none
      width 320px
      margin auto
      padding 20px 0
      > .authorlist
        > .author
          width 300px
          float none
          margin auto
          padding 10px 0
          > .asset
            width 300px
            height 300px
            margin auto

  footer
    > .inner
      padding 0 20px

