header
  > .inner
    padding 20px 60px
    > .logo
      width 520px
      height 200px
      margin auto
      > svg
        width inherit
        height inherit

    > .menu
      &.unstuck
        position absolute
      &.stuck
        position fixed
        top 0px
      border-top 2px solid gc(black1)
      border-bottom 2px solid gc(black1)
      padding 6px 0
      background-image url(/images/pattern/paper.png)
      top 300px
      left 0px
      right 0px
      width 100%
      > .outer
        border-top 1px solid gc(black1)
        border-bottom 1px solid gc(black1)
        > .inner
          width 980px
          margin auto
          > .left, > .right, > .item
            text-align center
            padding 10px
          > .left, > .right
            width 130px
          > .left
            float left
            border-right 1px solid gc(black1)
            height 35px
            > .loc
              margin 3px 0 0 0
              onoff()
            > .lg
              onoff()
              float right
              animation scaleInB 0.5s ease-in-out both
              > svg
                width 35px
                height 35px
          > .right
            float right
            border-left 1px solid gc(black1)
            > .socials
              > .social
                float left
                width 25px 
                height 25px 
                background-color gc(black2)
                padding 5px
                transition background-color 0.1s linear 0.1s
                border-radius 50%
                &:nth-child(2)
                  margin-left 10px
                &:nth-child(3)
                  margin-left 10px
                &:hover
                  background-color gc(brown3)
                  transition background-color 0.1s linear 0s

                > svg
                  width 15px
                  height 15px
                  margin 0 1px 4px 0
                  > path, > circle
                    fill gc(beige2)
          > .item
            float left
            padding 12px 24px
            color gc(black1)
            text-decoration none
            transition color 0.1s linear, text-decoration 0.1s linear 0.1s
            &:hover
              color gc(brown3)
              text-decoration underline
          > .divider
            float left
            width 2px
            height 20px
            background-color gc(black1)
            margin 20px 0 0 0



#Ornaments
  animation fadeIn 5s ease-in-out 0s both

/*
#Letters
  > g > path
    animation fadeIn 0.1s ease-in-out 0s both
    for num in 1..12
      &:nth-child({num})
        animation-delay unit(num*0.1, 's')


#Shadow01, #Shadow02
  animation fadeIn 0.2s ease-in-out 0.5s both
*/


@media all and (min-width: 1px) and (max-width: 1000px)
  header
    > .inner
      padding 10px
      > .logo
        width 100%
      > .menu
        > .outer
          > .inner
            width auto
            > .item
              display none
            > .divider
              display none
            > .left
              border-right none
              > .lg
                float left
            > .right
              float right
              > .socials
                float left


